import React from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import FormTextField from "../components/forms/FormTextField";
import FormSubmitButton from "../components/forms/FormSubmitButton";
import firebase from "../firebase";
import BlueDivider from "../components/BlueDivider";
import FormLabel from "../components/forms/FormLabel";
import HomePageContainer from "../components/HomePageContainer";

const AdminLoginPage: React.FC = () => {
  return (
    <HomePageContainer>
      <div className="bg-white shadow-lg max-w-lg rounded-lg text-black p-8 mx-auto">
        <h5 className="font-bold text-xl sm:text-2xl">Admin login</h5>
        <BlueDivider />
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            try {
              await firebase
                .auth()
                .signInWithEmailAndPassword(values.email, values.password);
            } catch {
              setStatus("Invalid login - please try again");
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, status }) => {
            return (
              <Form>
                {status && (
                  <div className="bg-red-700 text-white rounded text-center py-1 px-2 mb-2 text-sm">
                    <span>{status}</span>
                  </div>
                )}
                <div className="mb-4">
                  <Field name="email">
                    {({ field }: FieldProps) => {
                      return (
                        <>
                          <FormLabel label="Email" name={field.name} />
                          <FormTextField type="email" {...field} />
                        </>
                      );
                    }}
                  </Field>
                </div>
                <div className="mb-10">
                  <Field name="password">
                    {({ field }: FieldProps) => {
                      return (
                        <>
                          <FormLabel label="Password" name={field.name} />
                          <FormTextField type="password" {...field} />
                        </>
                      );
                    }}
                  </Field>
                </div>
                <div>
                  <FormSubmitButton isSubmitting={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </HomePageContainer>
  );
};

export default AdminLoginPage;
