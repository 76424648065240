import React from "react";
import Modal from "./Modal";
import { UseAdminDealerIdProps, useModal } from "../utils/hooks";
import FormLabel from "./forms/FormLabel";
import FormTextField from "./forms/FormTextField";
import Button from "./Button";
import Icon from "./Icon";
import { navButtonStyles } from "./Navbar";

const AdminDealerIdModal: React.FC<UseAdminDealerIdProps> = ({
  handleChange,
  handleSave,
  dealerIdInputValue,
  resetFormValue,
}) => {
  const { open, handleClose, handleOpen } = useModal();

  const handleSubmit = () => {
    handleSave();
    handleClose();
  };

  React.useEffect(() => {
    if (!open) {
      resetFormValue();
    }
  }, [open, resetFormValue]);

  return (
    <>
      <button className={navButtonStyles} onClick={handleOpen}>
        <Icon name="userCog" size="sm" />
        <span className="hidden sm:block pl-2">Assume Dealer</span>
      </button>
      <Modal open={open} handleClose={handleClose} title="Assume Dealer ID">
        <div>
          <div>
            <FormLabel name="assumedDealerId" label="Dealer ID" />
            <FormTextField
              name="assumedDealerId"
              onChange={handleChange}
              value={dealerIdInputValue}
            />
          </div>
          <div className="mt-4">
            <Button variant="blueContained" onClick={handleSubmit} fullWidth>
              Save Dealer ID
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminDealerIdModal;
