import React from "react";
import { DealerDashboardData } from "quipquotes-commons";
import BodyContainer from "../components/BodyContainer";
import LayoutContainer from "../components/LayoutContainer";
import LoadingIcon from "../components/LoadingIcon";
import Navbar from "../components/Navbar";
import ErrorMessage from "../components/ErrorMessage";
import { UseAdminDealerIdProps, useFetchRequest } from "../utils/hooks";
import { API_ENDPOINT } from "../config";
import DashboardPageBody from "../components/dashboard/DashboardPageBody";

const DashboardPage: React.FC<{ adminDealerIdProps?: UseAdminDealerIdProps }> =
  ({ adminDealerIdProps }) => {
    const { requestState } = useFetchRequest<DealerDashboardData>(
      `${API_ENDPOINT}/dealer-requests${
        adminDealerIdProps?.assumedDealerId
          ? `?dealerId=${adminDealerIdProps.assumedDealerId}`
          : ""
      }`
    );

    const renderContent = () => {
      if (requestState.status === "loading") {
        return (
          <div className="py-4">
            <LoadingIcon text="Loading data..." color="blue" size="large" />
          </div>
        );
      } else if (requestState.status === "error") {
        return <ErrorMessage message={requestState.errorMessage} />;
      } else if (requestState.data !== null) {
        return <DashboardPageBody dashboardData={requestState.data} />;
      }
      return null;
    };

    return (
      <BodyContainer>
        <Navbar
          adminDealerIdProps={adminDealerIdProps}
          headerLabel={requestState.data?.dealerLocations[0]?.dealerName}
        />
        <LayoutContainer>{renderContent()}</LayoutContainer>
      </BodyContainer>
    );
  };

export default DashboardPage;
