import React from "react";

const QuoteSubheader: React.FC<{ className?: string }> = ({
  children,
  className = "mt-6 mb-2",
}) => (
  <h5 className={`font-bold text-blueGray-600 ${className}`}>{children}</h5>
);

export default QuoteSubheader;
