import firebase from "firebase/app";
import "firebase/auth";

const credentials = {
  apiKey: "AIzaSyAtPr7jZT_1kX4_LNaGZ57Oxu5LnL90EyI",
  authDomain: "quipquotes-be003.firebaseapp.com",
  projectId: "quipquotes-be003",
  storageBucket: "quipquotes-be003.appspot.com",
  messagingSenderId: "135652350693",
  appId: "1:135652350693:web:8b3a9c5a463fc93541120c",
};

if (!firebase.apps.length) {
  firebase.initializeApp(credentials);
}

export default firebase;
