import { RequestStatuses } from "quipquotes-commons";
import React from "react";

const RequestStatus: React.FC<{ status: RequestStatuses }> = ({ status }) => {
  if (
    status === RequestStatuses.QUOTED_BY_DEALER ||
    status === RequestStatuses.QUOTE_ACKNOWLEDGED_BY_CUSTOMER
  ) {
    return <span className="text-green-700 font-bold">{status}</span>;
  } else if (
    status === RequestStatuses.CUSTOMER_NO_LONGER_ACTIVE ||
    status === RequestStatuses.DEALER_DECLINED_TO_QUOTE
  ) {
    return <span className="text-red-600 font-bold">{status}</span>;
  }
  return <span className="font-bold">{status}</span>;
};

export default RequestStatus;
