import React from "react";
import NumberFormat from "react-number-format";
import { getInputStyles } from "./utils";

interface FormPriceFieldProps {
  name: string;
  hasError?: boolean;
  setFieldValue: (fieldName: string, value: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  handleSetTotalPrice: (value: string) => void;
}

const FormPriceField: React.FC<FormPriceFieldProps> = ({
  name,
  hasError = false,
  setFieldValue,
  onChange,
  onBlur,
  value,
  handleSetTotalPrice,
}) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let [dollars, cents] = value.split(".");
    if (dollars.length > 1 && dollars[0] === "0") {
      dollars = dollars.slice(1);
    }
    let sanitizedValue = "";
    if (!dollars || dollars === "-") {
      sanitizedValue = "0.00";
    } else if (!cents) {
      sanitizedValue = `${dollars}.00`;
    } else if (cents.length > 2) {
      sanitizedValue = `${dollars}.${cents.slice(0, 2)}`;
    } else if (cents.length === 1) {
      sanitizedValue = `${dollars}.${cents}0`;
    } else {
      sanitizedValue = `${dollars}.${cents}`;
    }
    sanitizedValue = sanitizedValue.replace(/-/g, "");
    setFieldValue(name, sanitizedValue);
    handleSetTotalPrice(sanitizedValue);
    onBlur(e);
  };
  return (
    <div className="relative">
      <span className="absolute block top-0 bottom-0 left-2 flex items-center text-gray-700 text-xl leading-none pointer-events-none">
        $
      </span>
      <NumberFormat
        thousandSeparator={true}
        name={name}
        id={name}
        type="text"
        className={`${getInputStyles(hasError)} pl-7`}
        onChange={onChange}
        onBlur={handleBlur}
        value={value}
        inputMode="decimal"
      />
    </div>
  );
};

export default FormPriceField;
