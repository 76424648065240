import { convertCentsToCurrency, DealerRequestBody } from "quipquotes-commons";
import React from "react";
import { capitalize } from "../../utils";
import DataItem from "../DataItem";
import DataItemLabel from "../DataItemLabel";
import QuoteStatus from "./QuoteStatus";
import QuoteSubheader from "./QuoteSubheader";
import QuoteTotal from "./QuoteTotal";

const QuoteList: React.FC<{ quotes: DealerRequestBody["quotes"] }> = ({
  quotes,
}) => {
  return (
    <>
      {quotes.map((quote, quoteIndex) => {
        return (
          <div
            key={`${quoteIndex}`}
            className="p-4 bg-white rounded shadow-md mb-6"
          >
            <QuoteSubheader className="my-0">
              Quote #{quoteIndex + 1}
            </QuoteSubheader>
            <div className="my-4">
              <DataItem
                label="Quote status"
                value={
                  <QuoteStatus
                    status={quote.status}
                    reasonForDecline={quote.reasonForDecline}
                  />
                }
              />
            </div>
            <div className="my-4">
              <DataItem label="Quote type" value={capitalize(quote.type)} />
            </div>
            <div className="my-4">
              <DataItemLabel>Quote line items</DataItemLabel>
              <table className="w-full text-left mt-1">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Price{quote.type === "rental" ? " per month" : ""}</th>
                  </tr>
                </thead>
                <tbody>
                  {quote.priceItems.map((priceItem, index) => {
                    return (
                      <tr key={`price-item-${index}`}>
                        <td>{priceItem.description}</td>
                        <td>{convertCentsToCurrency(priceItem.price)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {quote.notes && (
              <div className="my-4">
                <DataItemLabel>Notes</DataItemLabel>
                <div
                  dangerouslySetInnerHTML={{ __html: quote.notes }}
                  className="content"
                />
              </div>
            )}
            <QuoteTotal total={quote.totalPrice} quoteType={quote.type} />
          </div>
        );
      })}
    </>
  );
};

export default QuoteList;
