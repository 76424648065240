import React from "react";
import DataItemLabel from "./DataItemLabel";

export interface DataItemProps {
  label: string;
  value: React.ReactNode;
}

const DataItem: React.FC<DataItemProps> = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div>
      <DataItemLabel>{label}</DataItemLabel>
      {typeof value === "string" ? (
        <span className="font-bold">{value}</span>
      ) : (
        value
      )}
    </div>
  );
};

export default DataItem;
