import { getSuperAgentErrorMessage } from "quipquotes-commons";
import React from "react";
import { useLocation } from "react-router-dom";
import * as superagent from "superagent";
import { setAuthHeaders } from ".";
import { RequestState } from "../types";
import { useAuth } from "../contexts/AuthContext";

export function useFetchRequest<
  RequestDataType,
  RequestMetadataType = undefined
>(url: string) {
  const { user } = useAuth();
  const [requestState, setRequestState] = React.useState<
    RequestState & {
      data: RequestDataType | null;
      metadata?: RequestMetadataType;
    }
  >({ status: "loading", data: null });

  const fetchRequest = React.useCallback(async () => {
    try {
      setRequestState({ data: null, status: "loading" });
      const { body } = await superagent
        .get(url)
        .set(await setAuthHeaders(user));
      setRequestState({
        status: "finished",
        data: body,
      });
    } catch (err) {
      setRequestState({
        status: "error",
        errorMessage: getSuperAgentErrorMessage(err),
        data: null,
      });
    }
  }, [url, user]);

  React.useEffect(() => {
    if (user) {
      fetchRequest();
    }
  }, [fetchRequest, user]);

  return { requestState, setRequestState };
}

export const useModal = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return { open, handleOpen, handleClose };
};

export const useLocalStorage = (key: string) => {
  const [state, setState] = React.useState<{
    value: string | null;
    fetching: boolean;
  }>({ value: null, fetching: true });

  React.useEffect(() => {
    const value = window.localStorage.getItem(key) || null;
    setState({ value: value ? JSON.parse(value) : null, fetching: false });
  }, [key]);

  const updateValue = (newValue: string) => {
    window.localStorage.setItem(key, JSON.stringify(newValue));
    setState({ value: newValue, fetching: false });
  };

  const removeValue = () => {
    window.localStorage.removeItem(key);
  };

  return {
    value: state.value,
    fetching: state.fetching,
    updateValue,
    removeValue,
  };
};

export interface UseAdminDealerIdProps {
  dealerIdInputValue: string;
  assumedDealerId: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  resetFormValue: () => void;
}

export const useAdminDealerId = (): UseAdminDealerIdProps => {
  const [assumedDealerId, setAssumedDealerId] = React.useState("");
  const [dealerIdInputValue, setDealerIdInputValue] = React.useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDealerIdInputValue(e.target.value);
  };
  const handleSave = () => {
    setAssumedDealerId(dealerIdInputValue);
  };

  const resetFormValue = () => {
    setDealerIdInputValue(assumedDealerId);
  };

  return {
    dealerIdInputValue,
    assumedDealerId,
    handleChange,
    handleSave,
    resetFormValue,
  };
};

export const useViewport = () => {
  const [viewport, setViewport] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    setViewport({ width: window.innerWidth, height: window.innerHeight });
    const listener = () => {
      setViewport({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [setViewport]);

  return viewport;
};

export const useQueryParams = () => {
  const { search } = useLocation();

  try {
    const query = React.useMemo(() => new URLSearchParams(search), [search]);
    return {
      email: query.get("email"),
      parentRequestId: query.get("parentRequestId"),
      requestId: query.get("requestId"),
    };
  } catch {
    return {
      email: null,
      parentRequestId: null,
      requestId: null,
    };
  }
};
