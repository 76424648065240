import { Quote, QuoteStatuses } from "quipquotes-commons";
import React from "react";

const QuoteStatus: React.FC<{
  status: Quote["status"];
  reasonForDecline: Quote["reasonForDecline"];
}> = ({ status, reasonForDecline }) => {
  if (status === QuoteStatuses.ACCEPTED_BY_CUSTOMER) {
    return <span className={`block font-bold text-green-700`}>{status}</span>;
  } else if (status === QuoteStatuses.DECLINED_BY_CUSTOMER) {
    return (
      <span className={`block font-bold text-red-600`}>
        {status}
        {reasonForDecline
          ? ` (Reason: ${reasonForDecline})`
          : " (no reason provided)"}
      </span>
    );
  }
  return <span className={`block font-bold`}>{status}</span>;
};

export default QuoteStatus;
