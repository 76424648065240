import React from "react";
import Tabs from "./Tabs";

const renderGridItemStyles = (
  activeTabIndex: number,
  index: number,
  alwaysVisible?: boolean
) =>
  `p-4 w-full ${activeTabIndex === index ? "block" : "hidden"} ${
    alwaysVisible ? "" : "lg:block lg:w-1/2"
  }`;

interface TabGridProps {
  items: {
    label: string;
    SectionComponent: React.ReactNode;
  }[];
  alwaysVisible?: boolean;
}

const TabGrid: React.FC<TabGridProps> = ({ items, alwaysVisible }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  return (
    <div>
      <div className={alwaysVisible ? "" : "block lg:hidden"}>
        <Tabs
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          labels={items.map(({ label }) => label)}
        />
      </div>
      <div className="flex flex-wrap -m-4">
        {items.map((item, index) => {
          return (
            <div
              className={renderGridItemStyles(
                activeTabIndex,
                index,
                alwaysVisible
              )}
              key={item.label}
            >
              {!alwaysVisible && (
                <h2
                  className={`font-bold text-3xl mb-2 text-blueGray-700 hidden lg:block`}
                >
                  {item.label}
                </h2>
              )}
              {item.SectionComponent}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabGrid;
