import { DealerDashboardData, DealerLocation } from "quipquotes-commons";
import React from "react";
import { useViewport } from "../../utils/hooks";
import DataItemGrid from "../DataItemGrid";

const LARGE_VIEWPORT_WIDTH = 1024;

const updateLocation = (location: DealerLocation) => ({
  ...location,
  manufacturers: location.manufacturers.join(", "),
});

const dealerKeys: { label: string; key: keyof DealerLocation }[] = [
  { label: "Name", key: "locationName" },
  { label: "Address", key: "address" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "ZIP code", key: "zip" },
  { label: "Associated manufacturers", key: "manufacturers" },
];

const DashboardDealerLocations: React.FC<{
  dealerLocations: DealerDashboardData["dealerLocations"];
}> = ({ dealerLocations }) => {
  const viewport = useViewport();

  if (dealerLocations.length === 0) {
    return <div className="text-gray-600">No dealer locations to show</div>;
  }

  if (viewport.width >= LARGE_VIEWPORT_WIDTH) {
    return (
      <div className="bg-white shadow-md rounded px-4 py-2">
        <table className="w-full text-left table-auto">
          <thead>
            <tr>
              {dealerKeys.map(({ label }) => (
                <th key={label}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dealerLocations.map((location) => {
              const updatedLocation = updateLocation(location);
              return (
                <tr key={location.id} className="border-t border-gray-200">
                  {dealerKeys.map(({ key }) => (
                    <td key={key} className="py-1">
                      {updatedLocation[key]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4">
      {dealerLocations.map((location) => {
        const updatedLocation = updateLocation(location);
        const dataItems = dealerKeys.map(({ key, label }) => ({
          label,
          value: updatedLocation[key],
        }));
        return (
          <div key={location.id} className="p-4 rounded bg-white shadow-md">
            <DataItemGrid dataItems={dataItems} />
          </div>
        );
      })}
    </div>
  );
};

export default DashboardDealerLocations;
