import React from "react";

const BodyContainer: React.FC = ({ children }) => {
  return (
    <main className="min-h-screen bg-gray-100 pt-14 lg:pt-16 pb-8 relative">
      {children}
    </main>
  );
};

export default BodyContainer;
