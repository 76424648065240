import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import AuthenticatingPage from "../pages/AuthenticatingPage";
import HomePage from "../pages/HomePage";
import DashboardPage from "../pages/DashboardPage";
import RequestPage from "../pages/RequestPage";
import AdminLoginPage from "../pages/AdminLoginPage";
import EmailLoginPage from "../pages/EmailLoginPage";
import { useAdminDealerId, useQueryParams } from "../utils/hooks";
import { ADMIN_UID } from "../config";

const Root: React.FC = () => {
  const { user, authenticating } = useAuth();
  const adminDealerIdProps = useAdminDealerId();

  if (authenticating) {
    return <AuthenticatingPage />;
  } else if (user) {
    return (
      <Switch>
        <Route path="/requests/:parentRequestId/:requestId" exact>
          <RequestPage />
        </Route>
        <Route path="/dashboard" exact>
          <DashboardPage
            adminDealerIdProps={
              user.uid === ADMIN_UID ? adminDealerIdProps : undefined
            }
          />
        </Route>
        <Route path="/login" exact>
          <LoginRedirectPage />
        </Route>
        <Route>
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    );
  }
  return (
    <Switch>
      <Route path="/admin">
        <AdminLoginPage />
      </Route>
      <Route path="/login" exact>
        <EmailLoginPage />
      </Route>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

function LoginRedirectPage() {
  const { parentRequestId, requestId } = useQueryParams();

  const redirectTo =
    parentRequestId && requestId
      ? `/requests/${parentRequestId}/${requestId}`
      : "/dashboard";

  return <Redirect to={redirectTo} />;
}

export default Root;
