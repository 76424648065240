import { BASE_QUIPQUOTES_URL } from "quipquotes-commons";
import React from "react";
import Button from "../Button";
import Modal from "../Modal";

const QuoteCreatedModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  numberOfQuotes: number;
}> = ({ open, handleClose, numberOfQuotes }) => {
  const plural = numberOfQuotes > 1;
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={`Quote${plural ? "s" : ""} submitted`}
    >
      <div>
        <p>
          Your {plural ? "quotes have" : "quote has"} been successfully
          submitted! We will notify the customer of your submission - please
          feel free to{" "}
          <a
            href={`${BASE_QUIPQUOTES_URL}/contact`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:underline focus:underline hover:text-blue-900 focus:text-blue-900"
          >
            contact us
          </a>{" "}
          or contact the customer as needed.
        </p>
        <div className="flex justify-end mt-4">
          <Button variant="whiteOutlined" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QuoteCreatedModal;
