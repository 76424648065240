import HomePageContainer from "../components/HomePageContainer";
import LoadingIcon from "../components/LoadingIcon";

export default function AuthenticatingPage() {
  return (
    <HomePageContainer>
      <LoadingIcon text="Authenticating..." size="large" color="white" />
    </HomePageContainer>
  );
}
