import React from "react";
import FormErrorMessage from "./FormErrorMessage";
import { getInputStyles } from "./utils";

interface FormTextFieldProps {
  name: string;
  type?: "text" | "email" | "password";
  placeholder?: string;
  errorMessage?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  type = "text",
  placeholder,
  errorMessage,
  onChange,
  onBlur,
  value,
}) => {
  return (
    <>
      <input
        name={name}
        id={name}
        type={type}
        className={getInputStyles(Boolean(errorMessage))}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      <FormErrorMessage message={errorMessage} />
    </>
  );
};

export default FormTextField;
