import React from "react";

const sizeClasses = {
  small: "w-1 h-1 mx-1",
  large: "w-4 h-4 mx-3",
};

const colorMap = {
  white: "bg-white",
  blue: "bg-blue-700",
};

const animationDelays: { [key: number]: string } = {
  0: "animation-delay-0",
  1: "animation-delay-1",
  2: "animation-delay-2",
};

const LoadingIcon: React.FC<{
  size: "small" | "large";
  color: "white" | "blue";
  text?: string;
}> = ({ size, color, text }) => {
  const icons = (
    <span className="flex items-center py-2">
      {Array.from({ length: 3 }, () => null).map((val, index) => (
        <span
          key={`circle-${index}`}
          className={`${sizeClasses[size]} ${colorMap[color]} ${animationDelays[index]} rounded-full animate-ping`}
        />
      ))}
    </span>
  );
  if (text) {
    return (
      <div className="flex flex-col justify-center items-center">
        {icons}
        <span
          className={`py-4 text-sm text-center ${
            color === "white" ? "text-white" : "text-gray-600"
          }`}
        >
          {text}
        </span>
      </div>
    );
  }
  return icons;
};

export default LoadingIcon;
