import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DealerLogoIcon from "./DealerLogoIcon";
import LayoutContainer from "./LayoutContainer";
import Icon from "./Icon";
import { UseAdminDealerIdProps } from "../utils/hooks";
import AdminDealerIdModal from "./AdminDealerIdModal";
import LogoutModal from "./LogoutModal";

export const navButtonStyles =
  "flex items-center text-white rounded opacity-80 hover:opacity-100 focus:opacity-100 uppercase text-sm disabled:opacity-50 disabled:pointer-events-none";

const DASHBOARD = "/dashboard";

const Navbar: React.FC<{
  adminDealerIdProps?: UseAdminDealerIdProps;
  headerLabel?: string;
}> = ({ adminDealerIdProps, headerLabel }) => {
  const { pathname } = useLocation();
  const { user } = useAuth();

  return (
    <>
      <nav className="absolute left-0 right-0 top-0 bg-blueGray-700 text-white shadow-md">
        <LayoutContainer>
          <div className="flex justify-between items-center h-12">
            <div className="w-48 sm:w-64">
              <DealerLogoIcon />
            </div>
            {user && (
              <div className="flex items-center justify-end">
                {adminDealerIdProps && (
                  <div className="mr-8 sm:mr-10">
                    <AdminDealerIdModal {...adminDealerIdProps} />
                  </div>
                )}
                {pathname !== DASHBOARD && (
                  <div className="mr-8 sm:mr-10">
                    <Link to={DASHBOARD} className={navButtonStyles}>
                      <Icon name="home" size="sm" />
                      <span className="hidden sm:block pl-2">Dashboard</span>
                    </Link>
                  </div>
                )}
                <div>
                  <LogoutModal />
                </div>
              </div>
            )}
          </div>
        </LayoutContainer>
      </nav>
      {headerLabel && (
        <header className="-mt-2 lg:-mt-4 py-1 bg-gray-200 border-b border-gray-300">
          <LayoutContainer>
            <span className="uppercase tracking-wider font-bold">
              {headerLabel}
            </span>
          </LayoutContainer>
        </header>
      )}
    </>
  );
};

export default Navbar;
