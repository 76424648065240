import React, { Fragment } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FieldArray, Field, FieldProps } from "formik";
import { convertPriceToCents } from "quipquotes-commons";
import FormTextField from "../forms/FormTextField";
import Icon from "../Icon";
import FormLabel from "../forms/FormLabel";
import Button from "../Button";
import FormPriceField from "../forms/FormPriceField";
import GrayDivider from "../GrayDivider";
import RemoveQuoteModal from "./RemoveQuoteModal";
import FormSelectField from "../forms/FormSelectField";
import QuoteHeader from "./QuoteHeader";
import QuoteSubheader from "./QuoteSubheader";
import QuoteTotal from "./QuoteTotal";
import { QuoteFieldValues } from "../../types";

const typeOptions = [
  { label: "Purchase", value: "purchase" },
  { label: "Lease", value: "lease" },
  { label: "Rental", value: "rental" },
];

const editorConfiguration = {
  toolbar: ["bold", "italic", "link", "numberedList", "bulletedList"],
};

const QuoteField: React.FC<{
  index: number;
  handleRemove: () => void;
  quote: QuoteFieldValues;
}> = ({ index, handleRemove, quote }) => {
  const [totalPriceItems, setTotalPriceItems] = React.useState(() =>
    quote.priceItems.map(() => "")
  );

  const total = totalPriceItems.reduce((a, b) => {
    a += convertPriceToCents(b);
    return a;
  }, 0);

  return (
    <div className="p-4 rounded bg-white shadow-md mb-6 relative">
      <header className="flex justify-between items-center mb-4">
        <QuoteHeader>Quote #{index + 1}</QuoteHeader>
        <RemoveQuoteModal handleRemove={handleRemove} />
      </header>
      <div className="mb-2">
        <Field name={`quotes.${index}.type`}>
          {({ field }: FieldProps) => {
            return (
              <div>
                <FormLabel label="Quote type" name={field.name} />
                <FormSelectField {...field} options={typeOptions} />
              </div>
            );
          }}
        </Field>
      </div>
      <FieldArray
        name={`quotes.${index}.priceItems`}
        render={({ push, remove }) => {
          const handleAdd = () => {
            push({ description: "", price: "0.00" });
          };
          return (
            <div>
              <QuoteSubheader>Quote line items</QuoteSubheader>
              {quote.priceItems.map((item, priceItemIndex) => {
                const handleRemove = () => {
                  remove(priceItemIndex);
                };
                return (
                  <Fragment key={`item-${priceItemIndex}`}>
                    <div className="my-3">
                      <span className="block font-medium text-gray-500 text-xs">
                        Line Item #{priceItemIndex + 1}
                      </span>
                      <div className="flex">
                        <div className="flex -mx-2 items-start w-full flex-wrap">
                          <div className="px-2 flex-grow">
                            <Field
                              name={`quotes.${index}.priceItems.${priceItemIndex}.description`}
                            >
                              {({ field, meta }: FieldProps) => {
                                return (
                                  <div>
                                    <FormLabel
                                      label="Description"
                                      name={field.name}
                                    />
                                    <FormTextField
                                      {...field}
                                      errorMessage={
                                        meta.touched && meta.error
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                          <div className="px-2 w-full sm:w-56">
                            <Field
                              name={`quotes.${index}.priceItems.${priceItemIndex}.price`}
                            >
                              {({ field, form }: FieldProps) => {
                                const handleSetTotalPrice = (value: string) => {
                                  setTotalPriceItems((vals) => {
                                    const newVals = [...vals];
                                    newVals[priceItemIndex] = value;
                                    return newVals;
                                  });
                                };
                                return (
                                  <div>
                                    <FormLabel
                                      label={
                                        quote.type === "rental"
                                          ? "Price per month"
                                          : "Price"
                                      }
                                      name={field.name}
                                    />
                                    <FormPriceField
                                      {...field}
                                      setFieldValue={form.setFieldValue}
                                      handleSetTotalPrice={handleSetTotalPrice}
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                        </div>
                        <div className="flex-grow-0 pt-7 -mr-2 pl-1">
                          <button
                            type="button"
                            onClick={handleRemove}
                            disabled={quote.priceItems.length === 1}
                            title="Remove this line item"
                            className="transition-colors h-8 w-8 rounded-full bg-white hover:bg-gray-200 focus:bg-gray-200 focus:outline-none flex items-center justify-center text-gray-600 disabled:pointer-events-none disabled:opacity-60"
                          >
                            <Icon name="delete" size="sm" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {priceItemIndex + 1 < quote.priceItems.length && (
                      <span className="sm:hidden">
                        <GrayDivider />
                      </span>
                    )}
                  </Fragment>
                );
              })}
              <div className="mt-4">
                <Button
                  onClick={handleAdd}
                  variant="blueOutlined"
                  size="small"
                  type="button"
                >
                  <Icon name="plus" size="xs" />
                  <span className="pl-1.5">Add Line Item</span>
                </Button>
              </div>
              <div>
                <QuoteSubheader>Notes</QuoteSubheader>
                <Field name={`quotes.${index}.notes`}>
                  {({ field, form }: FieldProps) => {
                    return (
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={field.value}
                        onChange={(
                          event: React.ChangeEvent<any>,
                          editor: any
                        ) => {
                          const data = editor.getData();
                          form.setFieldValue(field.name, data);
                        }}
                      />
                    );
                  }}
                </Field>
              </div>
            </div>
          );
        }}
      />
      <GrayDivider />
      <QuoteTotal total={total} quoteType={quote.type} />
    </div>
  );
};

export default QuoteField;
