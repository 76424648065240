import { convertCentsToCurrency, Quote } from "quipquotes-commons";
import React from "react";

const QuoteTotal: React.FC<{
  total: number;
  quoteType: Quote["type"];
}> = ({ total, quoteType }) => {
  return (
    <div>
      <span className="block text-sm text-gray-500">Quote total</span>
      <span className="font-bold text-3xl">
        {convertCentsToCurrency(total || 0)}
        {quoteType === "rental" && (
          <span className="text-base font-medium"> per month</span>
        )}
      </span>
    </div>
  );
};

export default QuoteTotal;
