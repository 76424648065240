import React from "react";

const ErrorMessage: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <div role="alert" className="text-center text-red-600">
      {message || "An unknown error occurred"}
    </div>
  );
};

export default ErrorMessage;
