import React from "react";
import { EMAIL_REG_EX } from "quipquotes-commons";
import { Link } from "react-router-dom";
import FormSubmitButton from "../components/forms/FormSubmitButton";
import BlueDivider from "../components/BlueDivider";
import FormLabel from "../components/forms/FormLabel";
import HomePageContainer from "../components/HomePageContainer";
import { useLocalStorage, useQueryParams } from "../utils/hooks";
import { LOCAL_STORAGE_EMAIL_KEY } from "../utils/constants";
import firebase from "../firebase";
import AuthenticatingPage from "./AuthenticatingPage";
import { getInputStyles } from "../components/forms/utils";
import FormErrorMessage from "../components/forms/FormErrorMessage";

const auth = firebase.auth();

const EmailLoginPage: React.FC = () => {
  const [state, setState] = React.useState<{
    error?: string;
    loading: boolean;
    emailValue: string | null;
  }>({
    loading: true,
    emailValue: null,
  });
  const [formError, setFormError] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const query = useQueryParams();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const emailValue = e.target[0].value;
    if (!EMAIL_REG_EX.test(emailValue)) {
      setFormError("Please enter a valid email");
    } else {
      setFormError("");
      setFormSubmitting(true);
      setState((current) => ({
        ...current,
        loading: true,
        emailValue,
      }));
    }
  };

  const {
    value: localStorageValue,
    fetching,
    removeValue,
  } = useLocalStorage(LOCAL_STORAGE_EMAIL_KEY);

  React.useEffect(() => {
    if (state.emailValue && !state.error) {
      auth
        .signInWithEmailLink(state.emailValue, window.location.href)
        .then(() => {
          removeValue();
        })
        .catch((error) => {
          setState((current) => ({
            ...current,
            loading: false,
            error:
              error.message || "An unknown error occurred - try again later.",
          }));
        });
    }
  }, [state, removeValue]);

  React.useEffect(() => {
    if (!state.error) {
      if (!auth.isSignInWithEmailLink(window.location.href)) {
        setState((current) => ({
          ...current,
          error: "Error - invalid link",
          loading: false,
        }));
      } else if (!state.emailValue) {
        if (query.email) {
          setState((current) => ({
            ...current,
            emailValue: query.email,
          }));
        } else if (!fetching) {
          if (localStorageValue) {
            setState((current) => ({
              ...current,
              emailValue: localStorageValue,
            }));
          } else {
            setState((current) => ({
              ...current,
              loading: false,
            }));
          }
        }
      }
    }
  }, [fetching, state, localStorageValue, query]);

  if (state.loading) {
    return <AuthenticatingPage />;
  }
  if (state.error) {
    return (
      <HomePageContainer>
        <div className="text-center max-w-screen-md m-auto">
          <span className="text-red-200 text-lg">{state.error}</span>
          <div className="mt-12">
            <Link
              className="text-white border border-white py-2 px-3 uppercase rounded bg-white bg-opacity-0 hover:bg-opacity-10 focus:bg-opacity-10"
              to="/"
            >
              Go back to home page
            </Link>
          </div>
        </div>
      </HomePageContainer>
    );
  }
  return (
    <HomePageContainer>
      <div className="bg-white shadow-lg max-w-lg rounded-lg text-black p-8 mx-auto">
        <h5 className="font-bold text-xl sm:text-2xl">
          Confirm your email to log in
        </h5>
        <BlueDivider />
        <form action="#" onSubmit={handleSubmit}>
          <div className="mb-4">
            <FormLabel label="Email" name="email" />
            <input
              type="email"
              name="email"
              className={getInputStyles(Boolean(formError))}
            />
            <FormErrorMessage message={formError} />
          </div>
          <div>
            <FormSubmitButton isSubmitting={formSubmitting} />
          </div>
        </form>
      </div>
    </HomePageContainer>
  );
};

export default EmailLoginPage;
