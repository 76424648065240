import { DealerRequestBody, termSections } from "quipquotes-commons";
import React, { Fragment } from "react";
import DataItemGrid from "../DataItemGrid";
import { DataItemProps } from "../DataItem";
import RequestSubheader from "./RequestSubheader";

const RequestTerms: React.FC<{ terms: DealerRequestBody["terms"] }> = ({
  terms,
}) => {
  const requestTerms = terms as Record<string, string>;
  return (
    <div className="-mt-2">
      {termSections.map(
        ({ label, termConfigurationMap, termConfigurations }) => {
          const dataItems: DataItemProps[] = [];
          termConfigurations.forEach((config) => {
            dataItems.push({
              label: config.label,
              value: requestTerms[config.name],
            });
            const optionalConfigs =
              termConfigurationMap[config.name][requestTerms[config.name]];
            if (optionalConfigs) {
              optionalConfigs.forEach((opt) => {
                dataItems.push({
                  label: opt.label,
                  value: requestTerms[opt.name],
                });
              });
            }
          });
          return (
            <Fragment key={label}>
              <RequestSubheader>{label}</RequestSubheader>
              <DataItemGrid dataItems={dataItems} />
            </Fragment>
          );
        }
      )}
    </div>
  );
};

export default RequestTerms;
