import React from "react";
import { useModal } from "../../utils/hooks";
import Button from "../Button";
import Icon from "../Icon";
import Modal from "../Modal";

const RemoveQuoteModal: React.FC<{
  handleRemove: () => void;
}> = ({ handleRemove }) => {
  const { open, handleClose, handleOpen } = useModal();

  const handleConfirm = () => {
    handleRemove();
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        type="button"
        variant="whiteOutlined"
        size="small"
      >
        <Icon name="delete" size="tiny" />
        <span className="pl-1.5">Remove</span>
      </Button>
      <Modal open={open} handleClose={handleClose} title="Remove quote">
        <div>
          <p>Are you sure you want to remove this quote?</p>
          <div className="flex justify-end items-center -mx-2 mt-4">
            <div className="px-2">
              <Button variant="whiteOutlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button variant="blueContained" onClick={handleConfirm}>
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveQuoteModal;
