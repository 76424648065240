import React from "react";
import { DealerDashboardData } from "quipquotes-commons";
import DashboardRequests from "./DashboardRequests";
import TabGrid from "../TabGrid";
import DashboardDealerLocations from "./DashboardDealerLocations";

const DashboardPageBody: React.FC<{ dashboardData: DealerDashboardData }> = ({
  dashboardData,
}) => {
  return (
    <TabGrid
      alwaysVisible
      items={[
        {
          label: "Quote Requests",
          SectionComponent: (
            <DashboardRequests requests={dashboardData.requests} />
          ),
        },
        {
          label: "Your Dealer Locations",
          SectionComponent: (
            <DashboardDealerLocations
              dealerLocations={dashboardData.dealerLocations}
            />
          ),
        },
      ]}
    />
  );
};

export default DashboardPageBody;
