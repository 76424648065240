import React from "react";
import { Formik, Form, FieldArray } from "formik";
import Button from "../Button";
import Icon from "../Icon";
import QuoteField from "./QuoteField";
import { convertCentsToCurrency, DealerRequestBody } from "quipquotes-commons";
import { SetRequestState } from "../../types";
import QuoteFormSubmitModal from "./QuoteFormSubmitModal";
import DeclineQuoteModal from "./DeclineQuoteModal";
import { useModal } from "../../utils/hooks";

const initialQuote = {
  notes: "",
  priceItems: [{ description: "", price: "0.00" }],
  type: "purchase",
};

const EXAMPLE_PRICE = 1000000;

const QuoteForm: React.FC<{
  setRequestState: SetRequestState;
  request: DealerRequestBody;
}> = ({ request, setRequestState }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <div>
      {request.machineQuantity > 1 && (
        <p className="text-gray-500 text-sm mb-2">
          <span className="font-bold">NOTE:</span> The customer has requested a
          machine quantity of {request.machineQuantity}. For all quotes, please
          quote the total price for <span className="font-bold">all</span>{" "}
          machines, not just the price of one machine. For example, if each
          machine will cost {convertCentsToCurrency(EXAMPLE_PRICE)}, the quote
          total should be{" "}
          {convertCentsToCurrency(request.machineQuantity * EXAMPLE_PRICE)}.
        </p>
      )}
      <Formik
        initialValues={{ quotes: [initialQuote] as any[] }}
        onSubmit={(values, actions) => {
          actions.resetForm({ values, submitCount: 0 });
          handleOpen();
        }}
        validate={({ quotes }) => {
          const errors: Record<string, any> = {};
          for (let i = 0; i < quotes.length; i++) {
            const quote = quotes[i];
            for (let j = 0; j < quote.priceItems.length; j++) {
              const priceItem = quote.priceItems[j];
              if (!priceItem.description.trim()) {
                if (!errors.quotes) {
                  errors.quotes = [];
                }
                if (!errors.quotes[i]) {
                  errors.quotes[i] = { priceItems: [] };
                } else if (!errors.quotes[i].priceItems) {
                  errors.quotes[i].priceItems = [];
                }
                errors.quotes[i].priceItems[j] = {
                  description: "Please include a description",
                };
              }
            }
          }
          return errors;
        }}
      >
        {({ values, errors, submitCount }) => {
          const hasErrors = submitCount > 0 && Object.keys(errors).length > 0;
          return (
            <>
              <div
                className={
                  values.quotes.length === 0 ? "py-2 -mt-2 sticky top-0" : ""
                }
              >
                <Form>
                  <FieldArray
                    name="quotes"
                    render={({ push, remove }) => {
                      const handleAdd = () => {
                        push(initialQuote);
                      };
                      return (
                        <div>
                          {values.quotes.map((quote, index) => {
                            const handleRemove = () => {
                              remove(index);
                            };
                            return (
                              <QuoteField
                                key={`quote-${index}`}
                                index={index}
                                handleRemove={handleRemove}
                                quote={values.quotes[index]}
                              />
                            );
                          })}
                          {hasErrors && (
                            <span className="block text-right text-sm text-red-600 mb-0.5 -mt-1">
                              Please correct the errors shown above
                            </span>
                          )}
                          <div className="flex justify-between flex-wrap">
                            <div className="w-full sm:w-auto mb-4 sm:mb-0">
                              <Button
                                onClick={handleAdd}
                                variant="blueOutlined"
                                size="large"
                                type="button"
                                disabled={values.quotes.length >= 3}
                                fullWidth
                              >
                                <Icon name="plus" size="xs" />
                                <span className="pl-2">Add quote</span>
                              </Button>
                            </div>
                            <div className="w-full sm:w-56">
                              {values.quotes.length > 0 && (
                                <Button
                                  variant="blueContained"
                                  size="large"
                                  disabled={values.quotes.length === 0}
                                  fullWidth
                                  type="submit"
                                >
                                  Submit quotes
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Form>
              </div>
              <DeclineQuoteModal
                setRequestState={setRequestState}
                parentRequestId={request.parentRequestId}
                requestId={request.id}
              />
              <QuoteFormSubmitModal
                open={open}
                handleClose={handleClose}
                parentRequestId={request.parentRequestId}
                requestId={request.id}
                setRequestState={setRequestState}
                quoteValues={values.quotes}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuoteForm;
