import React from "react";
import firebase from "../firebase";
import Button from "./Button";
import Icon from "./Icon";
import { navButtonStyles } from "./Navbar";
import Modal from "./Modal";
import { useModal } from "../utils/hooks";

const LogoutModal: React.FC = () => {
  const [signingOut, setSigningOut] = React.useState(false);
  const { open, handleClose, handleOpen } = useModal();

  const handleSignOut = async () => {
    try {
      setSigningOut(true);
      await firebase.auth().signOut();
    } catch {
      window.alert("An error occurred. Please try signing out again.");
      setSigningOut(false);
    }
  };
  return (
    <>
      <button className={navButtonStyles} onClick={handleOpen}>
        <Icon name="logout" size="sm" />
        <span className="hidden sm:block pl-2">Log Out</span>
      </button>
      <Modal title="Confirm log out" open={open} handleClose={handleClose}>
        <div>
          <p className="text-gray-600 mb-4 text-lg">
            Are you sure you want to sign out of your account?
          </p>
          <div className="flex justify-end items-center">
            <div className="pr-4">
              <Button
                variant="whiteOutlined"
                onClick={handleClose}
                disabled={signingOut}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="blueContained"
                onClick={handleSignOut}
                disabled={signingOut}
              >
                Log Out
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
