import React from "react";
import * as superagent from "superagent";
import { Formik, Form, Field, FieldProps } from "formik";
import { useModal } from "../../utils/hooks";
import Modal from "../Modal";
import Button from "../Button";
import { getSuperAgentErrorMessage } from "quipquotes-commons";
import { SetRequestState } from "../../types";
import { API_ENDPOINT } from "../../config";
import FormLabel from "../forms/FormLabel";
import FormTextArea from "../forms/FormTextArea";
import { setAuthHeaders } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";

const DeclineQuoteModal: React.FC<{
  setRequestState: SetRequestState;
  parentRequestId: string;
  requestId: string;
}> = ({ setRequestState, parentRequestId, requestId }) => {
  const { open, handleClose, handleOpen } = useModal();
  const { user } = useAuth();

  return (
    <>
      <div className="mt-20">
        <div className="flex items-center">
          <span className="h-px flex-grow bg-gray-300" />
          <span className="px-2 text-gray-400">OR</span>
          <span className="h-px flex-grow bg-gray-300" />
        </div>
        <div className="text-center mt-4">
          <button
            className="text-gray-600 opacity-70 hover:opacity-100 focus:opacity-100 text-sm focus:outline-black"
            onClick={handleOpen}
          >
            Decline to quote this request
          </button>
        </div>
      </div>
      <Modal title="Decline request" open={open} handleClose={handleClose}>
        <p className="mb-2 text-sm text-gray-500">
          Please note that if you decline to quote this request, this action
          cannot be undone.
        </p>
        <Formik
          initialValues={{ reasonForDecline: "" }}
          validate={(values) => {
            if (!values.reasonForDecline.trim()) {
              return {
                reasonForDecline:
                  "Please include a reason for declining this request",
              };
            }
            return {};
          }}
          onSubmit={async ({ reasonForDecline }, actions) => {
            actions.setStatus(null);
            try {
              const { body } = await superagent
                .post(`${API_ENDPOINT}/requests/decline`)
                .send({ parentRequestId, requestId, reasonForDecline })
                .set(await setAuthHeaders(user));
              setRequestState((current) => ({
                ...current,
                data: body,
                metadata: { modalOpen: "quoteDeclined" },
              }));
              window.scrollTo({ top: 0 });
            } catch (err) {
              actions.setStatus(getSuperAgentErrorMessage(err));
              actions.setSubmitting(false);
            }
          }}
        >
          {({ status, isSubmitting }) => {
            return (
              <Form>
                <div>
                  <Field name="reasonForDecline">
                    {({ field, meta }: FieldProps) => {
                      return (
                        <>
                          <FormLabel
                            label="Reason for declining"
                            name={field.name}
                          />
                          <FormTextArea
                            {...field}
                            errorMessage={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </>
                      );
                    }}
                  </Field>
                </div>
                {status && (
                  <span className="block text-red-600 mt-2 text-right">
                    {status}
                  </span>
                )}
                <div className="flex justify-end mt-4">
                  <div className="pr-4">
                    <Button
                      variant="whiteOutlined"
                      onClick={handleClose}
                      disabled={isSubmitting}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="blueContained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Decline Request
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default DeclineQuoteModal;
