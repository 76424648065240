import React from "react";
import { getInputStyles } from "./utils";

interface FormSelectFieldProps {
  name: string;
  options: { label: string; value: string }[];
  placeholder?: string;
  hasError?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
  value: string;
}

const FormSelectField: React.FC<FormSelectFieldProps> = ({
  name,
  options,
  placeholder,
  hasError = false,
  onChange,
  onBlur,
  value,
}) => {
  return (
    <select
      name={name}
      id={name}
      className={`${getInputStyles(hasError)} cursor-pointer`}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default FormSelectField;
