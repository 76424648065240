import React from "react";
import * as superagent from "superagent";
import { Formik, Form, Field, FieldProps } from "formik";
import FormTextField from "../components/forms/FormTextField";
import FormSubmitButton from "../components/forms/FormSubmitButton";
import BlueDivider from "../components/BlueDivider";
import FormLabel from "../components/forms/FormLabel";
import HomePageContainer from "../components/HomePageContainer";
import {
  BASE_QUIPQUOTES_URL,
  EMAIL_REG_EX,
  getSuperAgentErrorMessage,
} from "quipquotes-commons";
import { useLocalStorage, useModal } from "../utils/hooks";
import Modal from "../components/Modal";
import Button from "../components/Button";
import { API_ENDPOINT } from "../config";
import { LOCAL_STORAGE_EMAIL_KEY } from "../utils/constants";

const HomePage: React.FC = () => {
  const { open, handleOpen, handleClose } = useModal();
  const { updateValue } = useLocalStorage(LOCAL_STORAGE_EMAIL_KEY);

  return (
    <>
      <HomePageContainer>
        <div className="bg-white shadow-lg max-w-lg rounded-lg text-black p-8 mx-auto">
          <h5 className="font-bold text-xl sm:text-2xl">
            Log in to your account
          </h5>
          <BlueDivider />
          <p className="text-sm text-gray-600 mb-2">
            If you have a dealers account with us, enter your email address
            below and we'll send you a magic link which will automatically sign
            you into your account.
          </p>
          <Formik
            initialValues={{ email: "" }}
            validate={({ email }) => {
              if (!EMAIL_REG_EX.test(email)) {
                return { email: "Please enter a valid email" };
              }
              return {};
            }}
            onSubmit={async (
              values,
              { setStatus, setSubmitting, resetForm }
            ) => {
              try {
                await superagent
                  .post(`${API_ENDPOINT}/send-magic-link`)
                  .send({ email: values.email });
                updateValue(values.email);
                handleOpen();
                resetForm();
              } catch (err) {
                setStatus(getSuperAgentErrorMessage(err));
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, status }) => {
              return (
                <Form>
                  {status && (
                    <div className="bg-red-700 text-white rounded text-left py-1 px-2 mb-2 text-sm block">
                      <span>{status}</span>
                    </div>
                  )}
                  <div className="mb-4">
                    <Field name="email">
                      {({ field, meta }: FieldProps) => {
                        return (
                          <>
                            <FormLabel label="Email" name={field.name} />
                            <FormTextField
                              type="email"
                              {...field}
                              errorMessage={
                                meta.touched && meta.error ? meta.error : ""
                              }
                            />
                          </>
                        );
                      }}
                    </Field>
                  </div>
                  <div>
                    <FormSubmitButton
                      isSubmitting={isSubmitting}
                      text="Send Magic Link"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="text-center mt-16 text-white text-xs">
          <span className="opacity-70">
            If you do not have a dealer account and would like to request one,
            please{" "}
          </span>
          <a
            href={`${BASE_QUIPQUOTES_URL}/contact`}
            className="underline opacity-70 focus:opacity-100 hover:opacity-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            let us know
          </a>
          .
        </div>
      </HomePageContainer>
      <Modal title="Magic link sent!" open={open} handleClose={handleClose}>
        <div>
          <p>
            Please check your email - if there is an account associated with
            your email address, you will receive a magic link which you can
            click to log in.
          </p>
          <div className="flex justify-end mt-4">
            <Button variant="whiteOutlined" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HomePage;
