import {
  convertCentsToCurrency,
  convertPriceToCents,
  getSuperAgentErrorMessage,
} from "quipquotes-commons";
import React from "react";
import * as superagent from "superagent";
import { API_ENDPOINT } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import { QuoteFieldValues, SetRequestState } from "../../types";
import { capitalize, setAuthHeaders } from "../../utils";
import Button from "../Button";
import FormSubmitButton from "../forms/FormSubmitButton";
import Modal from "../Modal";

const DataItem: React.FC<{
  label: string;
  value: React.ReactNode;
  className?: string;
}> = ({ label, value, className = "" }) => {
  return (
    <div className={className}>
      <span className="block text-xs text-gray-500 leading-none">{label}</span>
      {value}
    </div>
  );
};

const QuoteFormSubmitModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  quoteValues: QuoteFieldValues[];
  parentRequestId: string;
  requestId: string;
  setRequestState: SetRequestState;
}> = ({
  quoteValues,
  parentRequestId,
  requestId,
  setRequestState,
  open,
  handleClose,
}) => {
  const { user } = useAuth();
  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = async () => {
    setSubmitting(true);
    const quotes = quoteValues.map((quote) => {
      return {
        ...quote,
        priceItems: quote.priceItems.map((priceItem: any) => {
          return {
            description: priceItem.description,
            price: convertPriceToCents(priceItem.price),
          };
        }),
      };
    });
    const requestBody = {
      parentRequestId,
      requestId,
      quotes,
    };
    try {
      const { body } = await superagent
        .post(`${API_ENDPOINT}/quotes`)
        .send(requestBody)
        .set(await setAuthHeaders(user));
      setRequestState((current) => ({
        ...current,
        data: body,
        metadata: { modalOpen: "quoteCreated" },
      }));
      setSubmitting(false);
      window.scrollTo({ top: 0 });
    } catch (err) {
      setErrorMessage(getSuperAgentErrorMessage(err));
      setSubmitting(false);
    }
  };

  return (
    <Modal title="Submit quotes" open={open} handleClose={handleClose}>
      <div>
        <p className="text-sm text-gray-600">
          Review your quotes below and ensure that they are correct before you
          submit them to the customer. You will not be able to edit quotes after
          submitting.
        </p>
        <div className="mt-4 mb-2">
          {quoteValues.map((quote, index) => {
            const quoteTotal = convertCentsToCurrency(
              quote.priceItems.reduce((a, b) => {
                a += convertPriceToCents(b.price);
                return a;
              }, 0)
            );
            const dataItems = [
              {
                label: "Type",
                value: (
                  <span className="block mb-1.5">{capitalize(quote.type)}</span>
                ),
              },
              {
                label: "Total",
                value: (
                  <span className="font-bold">
                    <span className="text-2xl">{quoteTotal}</span>
                    {quote.type === "rental" && <span> per month</span>}
                  </span>
                ),
                className: "mt-2",
              },
            ];
            return (
              <div
                key={`quote-${index}`}
                className="border-t border-gray-300 flex pt-3 pb-2"
              >
                <div className="w-8">
                  <h6 className="text-blueGray-700 font-bold text-sm leading-none">
                    #{index + 1}
                  </h6>
                </div>
                <div>
                  {dataItems.map((item) => (
                    <DataItem {...item} key={item.label} />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <div className="pt-4 border-t border-gray-300">
          {errorMessage && (
            <span className="block text-red-600 text-right mb-2 text-sm">
              {errorMessage}
            </span>
          )}
          <div className="flex justify-between items-center">
            <div>
              <Button
                variant="whiteOutlined"
                onClick={handleClose}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
            <div className="w-48">
              <FormSubmitButton
                isSubmitting={submitting}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuoteFormSubmitModal;
