import { DealerDashboardData } from "quipquotes-commons";
import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils";
import DataItemGrid from "../DataItemGrid";
import RequestStatus from "../RequestStatus";

const DashboardRequests: React.FC<{
  requests: DealerDashboardData["requests"];
}> = ({ requests }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {requests.length > 0 ? (
        requests.map((request) => {
          const dataItems = [
            {
              label: "Date submitted",
              value: formatDate(request.createdAt),
            },
            {
              label: "Request status",
              value: <RequestStatus status={request.status} />,
            },
            { label: "Machine requested", value: request.machineName },
            { label: "Product family", value: request.productFamily },
            {
              label: "Dealer location",
              value: request.formattedLocationName,
            },
            { label: "Customer name", value: request.customerName },
          ];
          return (
            <div key={request.id} className="p-4 rounded bg-white shadow-md">
              <DataItemGrid dataItems={dataItems} />
              <div className="flex justify-end border-t border-gray-300 mt-3 pt-3">
                <Link
                  to={`/requests/${request.parentRequestId}/${request.id}`}
                  className="w-full sm:w-auto py-2 px-4 text-white border-blue-700 bg-blue-700 hover:bg-blue-800 focus:bg-blue-800 shadow-sm focus:outline-black uppercase rounded transition-colors flex items-center justify-center disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                >
                  View Request
                </Link>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-gray-600">No requests to show yet</div>
      )}
    </div>
  );
};

export default DashboardRequests;
