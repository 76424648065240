import React from "react";
import DealerLogoIcon from "./DealerLogoIcon";

const HomePageContainer: React.FC = ({ children }) => {
  return (
    <div className="from-blueGray-900 to-blue-900 min-h-screen bg-gradient-to-tr p-4">
      <div className="w-80 mx-auto mt-6 mb-10 md:mt-10">
        <DealerLogoIcon />
      </div>
      {children}
    </div>
  );
};

export default HomePageContainer;
