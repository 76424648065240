import React from "react";

const BlueDivider: React.FC = () => {
  return (
    <span
      className="block w-16 sm:w-20 h-1 bg-blue-700 my-5"
      aria-hidden="true"
    />
  );
};

export default BlueDivider;
