import React from "react";
import firebase from "../firebase";

interface AuthState {
  user: firebase.User | null;
  authenticating: boolean;
}

function createCtx<A extends {} | null>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined)
      throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}

const [useAuth, AuthContextProvider] = createCtx<AuthState>();

const AuthProvider: React.FC = ({ children }) => {
  const [authState, setAuthState] = React.useState<AuthState>({
    user: null,
    authenticating: true,
  });

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setAuthState({
          user: authUser,
          authenticating: false,
        });
      } else {
        setAuthState({ user: null, authenticating: false });
      }
    });
  }, []);

  return (
    <AuthContextProvider value={authState}>{children}</AuthContextProvider>
  );
};

export { useAuth, AuthProvider };
