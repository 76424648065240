import { BASE_QUIPQUOTES_URL } from "quipquotes-commons";
import React from "react";
import Button from "../Button";
import Modal from "../Modal";

const QuoteDeclinedSuccessModal: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  return (
    <Modal open={open} handleClose={handleClose} title={`Request declined`}>
      <div>
        <p>
          You have successfully declined this request; we will notify the
          customer on your behalf. Free to{" "}
          <a
            href={`${BASE_QUIPQUOTES_URL}/contact`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:underline focus:underline hover:text-blue-900 focus:text-blue-900"
          >
            contact us
          </a>{" "}
          or contact the customer as needed.
        </p>
        <div className="flex justify-end mt-4">
          <Button variant="whiteOutlined" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QuoteDeclinedSuccessModal;
