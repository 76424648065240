import firebase from "firebase";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { DATE_FORMAT } from "quipquotes-commons";

export const setAuthHeaders = async (user: firebase.User | null) => {
  if (user) {
    return { Authorization: `Bearer ${await user.getIdToken()}` };
  }
  return {};
};
export const formatDate = (timestamp: number) =>
  format(parse(`${timestamp}`, "T", new Date()), DATE_FORMAT);

export const capitalize = (value: string) =>
  value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
