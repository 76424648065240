import React from "react";
import FormErrorMessage from "./FormErrorMessage";
import { getInputStyles } from "./utils";

interface FormTextAreaProps {
  name: string;
  errorMessage?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  value: string;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  errorMessage,
  onChange,
  onBlur,
  value,
}) => {
  return (
    <>
      <textarea
        name={name}
        id={name}
        className={`${getInputStyles(Boolean(errorMessage))} resize-none`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      <FormErrorMessage message={errorMessage} />
    </>
  );
};

export default FormTextArea;
