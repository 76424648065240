import React from "react";
import Button from "../Button";
import LoadingIcon from "../LoadingIcon";

const FormSubmitButton: React.FC<{
  isSubmitting?: boolean;
  disabled?: boolean;
  text?: string;
  onClick?: () => void;
}> = ({ isSubmitting, disabled, text = "Submit", onClick }) => {
  return (
    <Button
      fullWidth
      disabled={disabled || isSubmitting}
      type="submit"
      variant="blueContained"
      size="large"
      onClick={onClick}
    >
      {isSubmitting ? (
        <>
          <span className="pr-2">Submitting</span>
          <LoadingIcon size="small" color="white" />
        </>
      ) : (
        <span>{text}</span>
      )}
    </Button>
  );
};

export default FormSubmitButton;
