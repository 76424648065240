import React from "react";

const LayoutContainer: React.FC = ({ children }) => {
  return (
    <div className="px-4 sm:px-6 md:px-10 max-w-screen-2xl mx-auto">
      {children}
    </div>
  );
};

export default LayoutContainer;
