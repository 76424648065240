import React from "react";
import { DealerRequestBody, RequestStatuses } from "quipquotes-commons";
import QuoteForm from "../quotes/QuoteForm";
import { SetRequestState } from "../../types";
import QuoteList from "../quotes/QuoteList";
import RequestDetails from "./RequestDetails";
import TabGrid from "../TabGrid";

const RequestPageBody: React.FC<{
  request: DealerRequestBody;
  setRequestState: SetRequestState;
}> = ({ request, setRequestState }) => {
  const renderQuoteContent = () => {
    if (
      request.status === RequestStatuses.SUBMITTED_BY_CUSTOMER ||
      request.status === RequestStatuses.VIEWED_BY_DEALER
    ) {
      return <QuoteForm request={request} setRequestState={setRequestState} />;
    } else if (request.status === RequestStatuses.DEALER_DECLINED_TO_QUOTE) {
      return (
        <div className="text-red-600">
          <span className="block mb-2">
            No quotes to show - this request has been declined by the dealer.
          </span>
          <span className="block">
            <strong>Reason for declining:</strong> {request.reasonForDecline}
          </span>
        </div>
      );
    }
    return <QuoteList quotes={request.quotes} />;
  };

  return (
    <TabGrid
      items={[
        {
          label: "Customer Request",
          SectionComponent: (
            <div className="rounded bg-white p-4 shadow-md">
              <RequestDetails request={request} />
            </div>
          ),
        },
        {
          label: "Quotes",
          SectionComponent: renderQuoteContent(),
        },
      ]}
    />
  );
};

export default RequestPageBody;
