import React from "react";
import DataItem, { DataItemProps } from "./DataItem";

const DataItemGrid: React.FC<{ dataItems: DataItemProps[] }> = ({
  dataItems,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {dataItems.map((dataItem) => {
        return <DataItem key={dataItem.label} {...dataItem} />;
      })}
    </div>
  );
};

export default DataItemGrid;
