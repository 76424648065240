import React from "react";
import { useParams } from "react-router-dom";
import { DealerRequestBody } from "quipquotes-commons";
import BodyContainer from "../components/BodyContainer";
import LayoutContainer from "../components/LayoutContainer";
import LoadingIcon from "../components/LoadingIcon";
import Navbar from "../components/Navbar";
import ErrorMessage from "../components/ErrorMessage";
import { useFetchRequest } from "../utils/hooks";
import RequestPageBody from "../components/requests/RequestPageBody";
import { API_ENDPOINT } from "../config";
import { RequestMetadata } from "../types";
import QuoteCreatedModal from "../components/quotes/QuoteCreatedModal";
import QuoteDeclinedSuccessModal from "../components/quotes/QuoteDeclinedSuccessModal";

const RequestPage: React.FC = () => {
  const { parentRequestId, requestId } = useParams() as Record<string, string>;
  const { requestState, setRequestState } = useFetchRequest<
    { request: DealerRequestBody },
    RequestMetadata
  >(`${API_ENDPOINT}/requests/${parentRequestId}/${requestId}`);

  const renderContent = () => {
    if (requestState.status === "loading") {
      return (
        <div className="py-4">
          <LoadingIcon
            text="Loading request details..."
            color="blue"
            size="large"
          />
        </div>
      );
    } else if (requestState.status === "error") {
      return <ErrorMessage message={requestState.errorMessage} />;
    } else if (requestState.data !== null) {
      const handleModalClose = () => {
        setRequestState((current) => ({
          ...current,
          metadata: undefined,
        }));
      };
      return (
        <>
          <QuoteCreatedModal
            open={Boolean(requestState.metadata?.modalOpen === "quoteCreated")}
            handleClose={handleModalClose}
            numberOfQuotes={requestState.data.request.quotes.length}
          />
          <QuoteDeclinedSuccessModal
            open={Boolean(requestState.metadata?.modalOpen === "quoteDeclined")}
            handleClose={handleModalClose}
          />
          <RequestPageBody
            request={requestState.data.request}
            setRequestState={setRequestState}
          />
        </>
      );
    }
    return null;
  };

  return (
    <BodyContainer>
      <Navbar />
      <LayoutContainer>{renderContent()}</LayoutContainer>
    </BodyContainer>
  );
};

export default RequestPage;
