import React from "react";

const FormLabel: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  return (
    <label htmlFor={name} className="text-sm font-medium text-gray-700">
      {label}
    </label>
  );
};

export default FormLabel;
