export default function DealerLogoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 48" width="100%">
      <g
        transform="matrix(1.2785704,0,0,1.1990273,-55.527288,-2.5399139)"
        fill="#ffffff"
        aria-label="QuipQuotes"
      >
        <g>
          <path d="m 58.679523,33.043466 q -3.24584,0 -5.890599,-1.202163 -2.644759,-1.202163 -4.56822,-3.24584 -1.883388,-2.08375 -2.925263,-4.648364 -1.001803,-2.604687 -1.001803,-5.369662 0,-2.845119 1.081947,-5.409734 1.081947,-2.604686 3.005408,-4.6082912 1.963533,-2.0036051 4.608291,-3.1656961 2.684831,-1.202163 5.850527,-1.202163 3.24584,0 5.890599,1.2422351 2.644759,1.2422352 4.528148,3.3259845 1.883388,2.0837497 2.885191,4.6884357 1.041875,2.564615 1.041875,5.289518 0,2.805047 -1.081947,5.369661 -1.041875,2.564615 -3.005408,4.608292 -1.923461,2.003605 -4.568219,3.165696 -2.644759,1.162091 -5.850527,1.162091 z m 0.08014,-6.932474 q 1.683028,0 2.925263,-0.641153 1.242236,-0.641154 2.003606,-1.723101 0.801442,-1.081946 1.162091,-2.404326 0.400721,-1.362451 0.400721,-2.764975 0,-1.442596 -0.400721,-2.764975 -0.400722,-1.362451 -1.202164,-2.404326 -0.801442,-1.041875 -2.043677,-1.642956 -1.242235,-0.641154 -2.885191,-0.641154 -1.683028,0 -2.925263,0.641154 -1.202164,0.641154 -2.003606,1.7231 -0.801442,1.081947 -1.202163,2.444398 -0.360648,1.32238 -0.360648,2.724903 0,1.442596 0.400721,2.764975 0.400721,1.32238 1.202163,2.404326 0.801442,1.041875 2.003605,1.683029 1.242235,0.601081 2.925263,0.601081 z m -3.205768,-5.289517 h 7.293122 l 11.0599,12.02163 h -7.293122 z" />
          <path d="M 75.815355,25.630127 V 11.76518 h 7.613699 v 12.141847 q 0,1.362451 0.601082,2.163893 0.641153,0.76137 1.7231,0.76137 0.601082,0 1.202163,-0.160288 0.601082,-0.160289 1.202163,-0.641154 0.641154,-0.480865 1.242235,-1.362451 V 11.76518 h 7.6137 v 12.662784 q 0,1.081947 0.320577,1.52274 0.320576,0.400721 1.122018,0.480865 v 6.411536 q -1.001802,0.200361 -1.803244,0.280505 -0.801442,0.120216 -1.402524,0.120216 -1.923461,0 -3.045479,-0.721297 -1.122019,-0.721298 -1.442596,-2.203966 l -0.160289,-0.76137 q -1.482667,1.963533 -3.566417,2.845119 -2.043677,0.841514 -4.648363,0.841514 -3.165696,0 -4.888797,-1.963533 -1.683028,-1.963533 -1.683028,-5.650166 z" />
          <path d="M 101.60864,32.843105 V 11.76518 h 7.6137 v 21.077925 z m 0,-22.841098 V 3.5904712 h 7.6137 v 6.4115358 z" />
          <path d="m 127.22097,33.243826 q -2.28411,0 -3.96714,-0.881586 -1.68302,-0.921658 -2.60468,-2.644759 v 11.660982 h -7.6137 V 11.76518 h 6.6119 v 3.165696 q 1.12201,-1.7231 2.76497,-2.644759 1.68303,-0.921658 4.04728,-0.921658 2.12382,0 3.887,0.801442 1.76317,0.801442 3.08555,2.28411 1.32238,1.482667 2.04368,3.4462 0.72129,1.963533 0.72129,4.327787 0,3.165696 -1.16209,5.650167 -1.16209,2.48447 -3.20577,3.927066 -2.0036,1.442595 -4.60829,1.442595 z m -2.64476,-6.411536 q 0.84152,0 1.52274,-0.360649 0.7213,-0.360649 1.24224,-1.001802 0.52094,-0.641154 0.80144,-1.482668 0.28051,-0.841514 0.28051,-1.803245 0,-1.001802 -0.32058,-1.803244 -0.28051,-0.801442 -0.84151,-1.362452 -0.56101,-0.601081 -1.32238,-0.921658 -0.76137,-0.320577 -1.68303,-0.320577 -0.52094,0 -1.04188,0.160289 -0.48086,0.120216 -0.92166,0.400721 -0.44079,0.240432 -0.88158,0.641153 -0.40072,0.400721 -0.76137,0.961731 v 3.646561 q 0.36065,0.921658 0.96173,1.683028 0.64115,0.721298 1.40252,1.162091 0.76137,0.400721 1.56281,0.400721 z" />
        </g>
        <g>
          <path d="m 152.28545,33.043466 q -3.24584,0 -5.8906,-1.202163 -2.64476,-1.202163 -4.56822,-3.24584 -1.88339,-2.08375 -2.92526,-4.648364 -1.00181,-2.604687 -1.00181,-5.369662 0,-2.845119 1.08195,-5.409734 1.08195,-2.604686 3.00541,-4.6082912 1.96353,-2.0036051 4.60829,-3.1656961 2.68483,-1.202163 5.85053,-1.202163 3.24584,0 5.8906,1.2422351 2.64476,1.2422352 4.52814,3.3259845 1.88339,2.0837497 2.8852,4.6884357 1.04187,2.564615 1.04187,5.289518 0,2.805047 -1.08195,5.369661 -1.04187,2.564615 -3.0054,4.608292 -1.92347,2.003605 -4.56822,3.165696 -2.64476,1.162091 -5.85053,1.162091 z m 0.0801,-6.932474 q 1.68303,0 2.92527,-0.641153 1.24223,-0.641154 2.0036,-1.723101 0.80144,-1.081946 1.16209,-2.404326 0.40072,-1.362451 0.40072,-2.764975 0,-1.442596 -0.40072,-2.764975 -0.40072,-1.362451 -1.20216,-2.404326 -0.80144,-1.041875 -2.04368,-1.642956 -1.24223,-0.641154 -2.88519,-0.641154 -1.68303,0 -2.92526,0.641154 -1.20216,0.641154 -2.00361,1.7231 -0.80144,1.081947 -1.20216,2.444398 -0.36065,1.32238 -0.36065,2.724903 0,1.442596 0.40072,2.764975 0.40072,1.32238 1.20217,2.404326 0.80144,1.041875 2.0036,1.683029 1.24224,0.601081 2.92526,0.601081 z m -3.20576,-5.289517 h 7.29312 l 11.0599,12.02163 h -7.29312 z" />
          <path d="M 169.42128,25.630127 V 11.76518 h 7.6137 v 12.141847 q 0,1.362451 0.60108,2.163893 0.64115,0.76137 1.7231,0.76137 0.60108,0 1.20216,-0.160288 0.60108,-0.160289 1.20216,-0.641154 0.64116,-0.480865 1.24224,-1.362451 V 11.76518 h 7.6137 v 12.662784 q 0,1.081947 0.32058,1.52274 0.32057,0.400721 1.12202,0.480865 v 6.411536 q -1.00181,0.200361 -1.80325,0.280505 -0.80144,0.120216 -1.40252,0.120216 -1.92346,0 -3.04548,-0.721297 -1.12202,-0.721298 -1.4426,-2.203966 l -0.16029,-0.76137 q -1.48266,1.963533 -3.56641,2.845119 -2.04368,0.841514 -4.64837,0.841514 -3.16569,0 -4.88879,-1.963533 -1.68303,-1.963533 -1.68303,-5.650166 z" />
          <path d="m 205.59323,33.243826 q -2.80505,0 -4.96894,-0.881586 -2.16389,-0.881586 -3.64656,-2.404326 -1.4426,-1.562812 -2.20397,-3.526345 -0.72129,-1.963533 -0.72129,-4.127426 0,-2.163894 0.72129,-4.127427 0.76137,-2.003605 2.20397,-3.526345 1.48267,-1.52274 3.64656,-2.404326 2.16389,-0.881586 4.96894,-0.881586 2.84512,0 4.96894,0.881586 2.1639,0.881586 3.60649,2.404326 1.48267,1.52274 2.20397,3.526345 0.76137,1.963533 0.76137,4.127427 0,2.163893 -0.76137,4.127426 -0.7213,1.963533 -2.20397,3.526345 -1.44259,1.52274 -3.60649,2.404326 -2.12382,0.881586 -4.96894,0.881586 z m -3.7267,-10.939683 q 0,1.402523 0.48086,2.444398 0.48087,1.001802 1.32238,1.562812 0.84151,0.520937 1.92346,0.520937 1.08195,0 1.92346,-0.520937 0.84152,-0.56101 1.32238,-1.562812 0.48087,-1.041875 0.48087,-2.444398 0,-1.402524 -0.48087,-2.404326 -0.48086,-1.041875 -1.32238,-1.562812 -0.84151,-0.56101 -1.92346,-0.56101 -1.08195,0 -1.92346,0.56101 -0.84151,0.520937 -1.32238,1.562812 -0.48086,1.001802 -0.48086,2.404326 z" />
          <path d="m 234.26357,31.681014 q -1.04188,0.440793 -2.24404,0.841515 -1.16209,0.360648 -2.40433,0.561009 -1.20216,0.20036 -2.36425,0.20036 -1.7231,0 -3.1657,-0.561009 -1.44259,-0.601082 -2.32418,-1.923461 -0.84151,-1.362451 -0.84151,-3.646561 V 17.49549 h -2.60469 v -5.73031 h 2.60469 V 5.1532831 h 7.6137 V 11.76518 h 4.12742 v 5.73031 h -4.12742 v 6.972546 q 0,1.041875 0.44079,1.482668 0.44079,0.400721 1.20216,0.400721 0.56101,0 1.28231,-0.200361 0.7213,-0.20036 1.4426,-0.480865 z" />
          <path d="m 246.60452,33.243826 q -2.76497,0 -4.92887,-0.841514 -2.16389,-0.881586 -3.64656,-2.364254 -1.44259,-1.482668 -2.20396,-3.406128 -0.76137,-1.923461 -0.76137,-4.047283 0,-3.045479 1.32238,-5.570022 1.32238,-2.564614 3.88699,-4.087354 2.56461,-1.562812 6.33139,-1.562812 3.76678,0 6.33139,1.52274 2.56462,1.52274 3.887,4.00721 1.32238,2.48447 1.32238,5.409734 0,0.601081 -0.0802,1.242235 -0.0401,0.601081 -0.12021,1.081946 h -14.78661 q 0.0802,1.122019 0.60108,1.843317 0.56101,0.681226 1.36245,1.001803 0.84152,0.320577 1.72311,0.320577 1.24223,0 2.32418,-0.56101 1.12202,-0.561009 1.48266,-1.562812 l 6.45161,1.843317 q -0.84151,1.683028 -2.36425,2.965335 -1.48267,1.282308 -3.52635,2.043678 -2.04367,0.721297 -4.60829,0.721297 z m -3.60649,-13.183721 h 6.97255 q -0.12022,-1.041875 -0.60108,-1.763173 -0.4408,-0.721297 -1.20217,-1.122018 -0.72129,-0.400721 -1.68302,-0.400721 -0.96173,0 -1.7231,0.400721 -0.7213,0.400721 -1.20217,1.122018 -0.44079,0.721298 -0.56101,1.763173 z" />
          <path d="m 269.10001,33.243826 q -2.76498,0 -5.44981,-0.881586 -2.64476,-0.921658 -4.48807,-2.644759 l 2.60468,-4.688435 q 1.96354,1.282307 3.76678,2.003605 1.84332,0.721297 3.32598,0.721297 0.84152,0 1.24224,-0.20036 0.40072,-0.240433 0.40072,-0.76137 0,-0.360649 -0.2805,-0.601082 -0.28051,-0.240432 -0.92166,-0.480865 -0.60108,-0.240432 -1.56281,-0.520937 -2.48447,-0.881586 -4.1675,-1.642956 -1.64296,-0.76137 -2.48447,-1.843317 -0.80145,-1.122019 -0.80145,-2.965335 0,-2.163894 1.08195,-3.80685 1.08195,-1.683028 3.08555,-2.644759 2.04368,-0.96173 4.8888,-0.96173 2.12382,0 4.24764,0.681225 2.1639,0.641154 4.1675,2.364254 l -2.88519,4.648364 q -2.00361,-1.242235 -3.40613,-1.763172 -1.36245,-0.520938 -2.4444,-0.520938 -0.52093,0 -0.92166,0.120217 -0.36064,0.120216 -0.56101,0.360649 -0.20036,0.240432 -0.20036,0.641153 0,0.400721 0.28051,0.681226 0.2805,0.240433 0.88159,0.480865 0.60108,0.200361 1.52274,0.480865 2.7249,0.801443 4.448,1.642957 1.7231,0.801442 2.52454,1.963533 0.84152,1.162091 0.84152,3.045479 0,3.325985 -2.36426,5.209374 -2.32418,1.883388 -6.37146,1.883388 z" />
        </g>
        <g aria-label="DEALERS" transform="scale(1.0288779,0.97193262)">
          <g
            aria-label="DEALERS"
            transform="matrix(0.97749545,0,0,0.94145386,7.2363889,2.7018282)"
          >
            <path d="M 292.411,32.868912 V 10.452863 h 8.14555 q 3.66234,0 6.12495,1.515451 2.4626,1.48388 3.69391,4.041203 1.2313,2.525752 1.2313,5.619799 0,3.409765 -1.35759,5.935517 -1.35759,2.525752 -3.85177,3.914915 -2.46261,1.389164 -5.8408,1.389164 z m 14.80722,-11.239596 q 0,-2.178462 -0.7893,-3.8202 -0.7893,-1.673311 -2.27318,-2.588896 -1.48388,-0.947157 -3.59919,-0.947157 h -3.78863 v 14.775649 h 3.78863 q 2.14689,0 3.63077,-0.947157 1.48387,-0.978729 2.2416,-2.65204 0.7893,-1.704882 0.7893,-3.820199 z" />
            <path d="m 333.02953,29.048712 v 3.8202 H 317.46459 V 10.452863 h 15.2808 v 3.8202 h -10.92388 v 5.398795 h 9.44 v 3.536053 h -9.44 v 5.840801 z" />
            <path d="m 344.97338,10.452863 h 4.42007 l 8.24026,22.416049 h -4.4832 l -2.08375,-5.588226 h -7.82983 l -2.05217,5.588226 h -4.48321 z m 5.30408,13.765348 -3.09404,-9.250566 -3.22034,9.250566 z" />
            <path d="M 362.51532,32.868912 V 10.452863 h 4.35692 v 18.595849 h 11.42903 v 3.8202 z" />
            <path d="m 398.94072,29.048712 v 3.8202 H 383.37577 V 10.452863 h 15.2808 v 3.8202 h -10.92388 v 5.398795 h 9.44 v 3.536053 h -9.44 v 5.840801 z" />
            <path d="M 404.79119,32.868912 V 10.452863 h 9.91358 q 1.54702,0 2.84147,0.631438 1.32602,0.631438 2.27318,1.704883 0.97873,1.073444 1.48388,2.399464 0.53672,1.32602 0.53672,2.715183 0,1.420736 -0.50515,2.746756 -0.47358,1.294448 -1.38917,2.273176 -0.91558,0.978729 -2.14688,1.515452 l 5.11464,8.429697 h -4.79893 L 413.50504,25.3548 h -4.35693 v 7.514112 z M 409.14811,21.5346 h 5.46194 q 0.82087,0 1.45231,-0.473579 0.63144,-0.50515 1.0103,-1.326019 0.37886,-0.82087 0.37886,-1.831171 0,-1.073444 -0.442,-1.862742 -0.44201,-0.820869 -1.13659,-1.294448 -0.66301,-0.473578 -1.45231,-0.473578 h -5.27251 z" />
            <path d="m 441.53477,16.325236 q -0.221,-0.221003 -0.82087,-0.599866 -0.56829,-0.378862 -1.42073,-0.726153 -0.82087,-0.347291 -1.7996,-0.599866 -0.97873,-0.284148 -1.98903,-0.284148 -1.76803,0 -2.68361,0.66301 -0.88402,0.66301 -0.88402,1.862742 0,0.915586 0.5683,1.452308 0.56829,0.536722 1.70488,0.915585 1.13659,0.378863 2.84147,0.820869 2.21003,0.536723 3.8202,1.32602 1.64174,0.757726 2.49418,2.020602 0.88401,1.231304 0.88401,3.283477 0,1.799598 -0.66301,3.094046 -0.66301,1.262876 -1.83117,2.052174 -1.16816,0.789297 -2.65204,1.16816 -1.48388,0.347291 -3.15719,0.347291 -1.67331,0 -3.34662,-0.347291 -1.67331,-0.347291 -3.22033,-0.978729 -1.54702,-0.66301 -2.84147,-1.578595 l 1.92588,-3.757056 q 0.28415,0.284147 1.0103,0.757726 0.72616,0.442006 1.76803,0.915585 1.04187,0.442006 2.27318,0.757725 1.2313,0.315719 2.49418,0.315719 1.76802,0 2.68361,-0.599866 0.91558,-0.599866 0.91558,-1.704882 0,-1.010301 -0.72615,-1.578595 -0.72615,-0.568294 -2.0206,-0.978729 -1.29445,-0.442007 -3.06247,-0.947157 -2.11532,-0.599866 -3.53606,-1.32602 -1.42073,-0.757725 -2.11531,-1.894314 -0.69459,-1.136588 -0.69459,-2.841471 0,-2.304748 1.07345,-3.851771 1.10501,-1.578595 2.96776,-2.367893 1.86274,-0.820869 4.13592,-0.820869 1.57859,0 2.96775,0.347291 1.42074,0.34729 2.65204,0.915585 1.23131,0.568294 2.21004,1.231304 z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
