import { DealerRequestBody } from "quipquotes-commons";
import React, { Fragment } from "react";
import DataItemGrid from "../DataItemGrid";
import RequestConfigurations from "./RequestConfigurations";
import RequestTerms from "./RequestTerms";
import { formatDate } from "../../utils";
import GrayDivider from "../GrayDivider";
import RequestStatus from "../RequestStatus";

const RequestDetails: React.FC<{ request: DealerRequestBody }> = ({
  request,
}) => {
  const sections: { heading: string; Component: React.ReactNode }[] = [
    {
      heading: "Request Details",
      Component: (
        <DataItemGrid
          dataItems={[
            { label: "Dealer location", value: request.dealerLocationName },
            { label: "Date submitted", value: formatDate(request.createdAt) },
            {
              label: "Request status",
              value: <RequestStatus status={request.status} />,
            },
            { label: "Machine requested", value: request.machine.name },
            { label: "Product family", value: request.productFamily },
            { label: "Delivery location", value: request.deliveryLocation },
            { label: "Machine quantity", value: request.machineQuantity },
            { label: "Need by date", value: request.needByDate },
          ]}
        />
      ),
    },
    {
      heading: "Customer Details",
      Component: (
        <DataItemGrid
          dataItems={[
            { label: "Name", value: request.accountDetails.name },
            {
              label: "Owner class",
              value: request.accountDetails.ownerClass,
            },
            { label: "Email", value: request.accountDetails.email },
            {
              label: "Phone number",
              value: request.accountDetails.phone,
            },
            {
              label: "Business name",
              value: request.accountDetails.businessName,
            },
            {
              label: "Market segment",
              value: request.accountDetails.marketSegment,
            },
          ]}
        />
      ),
    },
    {
      heading: "Configurations",
      Component: (
        <RequestConfigurations
          configurations={request.machineConfigurations}
          productFamily={request.productFamily}
        />
      ),
    },
    {
      heading: "Terms",
      Component: <RequestTerms terms={request.terms} />,
    },
  ];

  return (
    <div>
      {sections.map(({ heading, Component }, index) => {
        return (
          <Fragment key={heading}>
            <section>
              <h4 className="font-bold text-2xl mb-4">{heading}</h4>
              {Component}
            </section>
            {index + 1 < sections.length && <GrayDivider />}
          </Fragment>
        );
      })}
      {request.additionalNotes && (
        <>
          <GrayDivider />
          <section>
            <h4 className="font-bold text-2xl mb-4">Customer Notes</h4>
            <p>{request.additionalNotes}</p>
          </section>
        </>
      )}
    </div>
  );
};

export default RequestDetails;
