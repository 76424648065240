import {
  DealerRequestBody,
  productFamilyConfigurations,
} from "quipquotes-commons";
import React from "react";
import DataItemGrid from "../DataItemGrid";
import RequestSubheader from "./RequestSubheader";

const RequestConfigurations: React.FC<{
  configurations: DealerRequestBody["machineConfigurations"];
  productFamily: string;
}> = ({ configurations, productFamily }) => {
  return (
    <div className="-mt-2">
      {configurations.attachments.length > 0 && (
        <>
          <RequestSubheader>Attachments</RequestSubheader>
          <table className="-mt-1">
            <tbody>
              {configurations.attachments.map((attachment, index) => {
                return (
                  <tr key={attachment.name}>
                    <td className="w-6">{index + 1}.</td>
                    <td className="font-bold">
                      <span>{attachment.name}</span>
                      {attachment.notes && (
                        <span className="text-gray-500 pl-4">
                          (Notes: {attachment.notes})
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <RequestSubheader>Machine Options</RequestSubheader>
      <DataItemGrid
        dataItems={productFamilyConfigurations[
          productFamily
        ].machineOptions.map((option) => {
          return {
            label: option.label,
            value: configurations.machineOptions[option.name],
          };
        })}
      />
      <RequestSubheader>Technology Configurations</RequestSubheader>
      <DataItemGrid
        dataItems={productFamilyConfigurations[
          productFamily
        ].technologyConfigurations.map((configuration) => {
          return {
            label: configuration.label,
            value: configurations.technology[configuration.name],
          };
        })}
      />
    </div>
  );
};

export default RequestConfigurations;
