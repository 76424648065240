import React from "react";

const Tabs: React.FC<{
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  labels: string[];
}> = ({ activeTabIndex, setActiveTabIndex, labels }) => {
  return (
    <div className="flex border-b border-gray-300 mb-4">
      {labels.map((tab, index) => {
        const handleClick = () => {
          setActiveTabIndex(index);
        };
        const active = index === activeTabIndex;
        return (
          <button
            key={tab}
            onClick={handleClick}
            className={`relative py-2 font-bold ${index === 0 ? "mr-8" : ""} ${
              active
                ? "text-blue-700 disabled:pointer-events-none"
                : "opacity-60 hover:opacity-80 focus:opacity-80"
            }`}
            disabled={active}
          >
            {tab}
            {active && (
              <span
                className="absolute h-1 bg-blue-700 left-0 bottom-0 right-0 rounded-t block"
                aria-hidden="true"
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
