import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Icon from "./Icon";

const Modal: React.FC<{
  open: boolean;
  handleClose: () => void;
  title: string;
}> = ({ open, handleClose, title, children }) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md overflow-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="p-4 relative">
                  <button
                    className="absolute right-1 top-1 w-6 h-6 focus:outline-none focus:opacity-75 hover:opacity-75 opacity-50"
                    onClick={handleClose}
                    type="button"
                  >
                    <Icon name="close" size="md" />
                  </button>
                  <Dialog.Title as="h4" className="mb-3 font-bold text-2xl">
                    {title}
                  </Dialog.Title>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
