import React from "react";

const variantStyles = {
  blueContained:
    "text-white border-blue-700 bg-blue-700 hover:bg-blue-800 focus:bg-blue-800 shadow-sm",
  blueOutlined:
    "border text-blue-700 border-blue-700 bg-white hover:bg-blue-100 focus:bg-blue-100",
  whiteOutlined:
    "border text-gray-700 border-gray-300 bg-white hover:bg-gray-100 focus:bg-gray-100",
};

const sizeStyles = {
  large: "py-3 px-6 text-base",
  default: "py-2 px-4 text-base",
  small: "py-1.5 px-3 text-sm",
};

const Button: React.FC<{
  variant: "blueContained" | "blueOutlined" | "whiteOutlined";
  size?: "large" | "default" | "small";
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}> = ({
  variant,
  size = "default",
  children,
  fullWidth,
  disabled,
  type,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`${fullWidth ? "w-full" : ""} ${sizeStyles[size]} ${
        variantStyles[variant]
      } focus:outline-black uppercase rounded transition-colors flex items-center justify-center disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none`}
    >
      {children}
    </button>
  );
};

export default Button;
